import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import TopMenu from "./Header/TopMenu/TopMenu";
// import FilterMobilePanel from "../FilterMobile/FilterMobilePanel";
import PopupBlock from "./Header/PopupBlock/PopupBlock";

function Layout() {
  return (
    <>
      <Header />
      <div className="container">
        <TopMenu />
        <Outlet />
        <Footer />
        <PopupBlock />
      </div>
    </>
  );
}
export default Layout;
