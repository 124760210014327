import React, { useState } from "react";
import { SITE_NAME } from "../../../constants/General";
import AnalyticPeriodFilter from "./AnalyticPeriodFilter";

export default function AnalyticTopSection({
  selectedPeriod,
  setFilterPeriod,
}) {
  return (
    <section className="data-v__header">
      <div className="data-v__title-block">
        <h1 className="page-title">Data visualization</h1>
        <a className="arrowed-link" href="">
          View all metrics
        </a>
        <div className="data-v__chainspot-badge chainspot-badge">
          <img src="img/chainspot-icon.png" width="19" height="19" alt="" />
          <span>{SITE_NAME}</span>
        </div>
      </div>
      <div className="data-v__timeline">
        <span className="data-v__timeline-title">Timeline</span>
        <div className="data-v__timeline-switch multi-switch">
          <AnalyticPeriodFilter
            selectedPeriod={selectedPeriod}
            setFilterPeriod={setFilterPeriod}
          />
        </div>
      </div>
    </section>
  );
}
