import React from "react";
import AnalyticPeriodFilter from "../AnalyticTop/AnalyticPeriodFilter";

export default function AnalyticGridHeader({
  selectedPeriod,
  setFilterPeriod,
}) {
  return (
    <>
      <div className="analytics__header">
        <h2 className="analytics__title page-title">Analytics</h2>
        <div className="analytics__period">
          <span className="analytics__period-title">Period</span>
          <div className="analytics__period-switch multi-switch">
            <AnalyticPeriodFilter
              selectedPeriod={selectedPeriod}
              setFilterPeriod={setFilterPeriod}
              name={'test'}
            />
          </div>
        </div>
      </div>
    </>
  );
}
