//Вместо HECO и Bitgert ставим BOBA и CELO
//Минус BOBA, CELO, Everscale и Arbitrum

import { CHAIN_TYPE_EVM } from "./ChainTypes";
import { PROVIDER_METAMASK } from "./ProviderTypes";

//Ethereum,Polygon,Fantom,Optimism,BNB,Avalanche
export const DEF_MAINNET_CHAINS = [
  {
    id: 1,
    chainId: 0,
    name: "Ethereum",
    img: "ethereum-24x24.svg",
    middleImg: 'img/ethereum-icon.svg',
    // bigImg: 'img/ethereum-icon.svg',
    
    rpcUrls: [
      "https://eth.llamarpc.com",
      "https://ethereum.blockpi.network/v1/rpc/public",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH", // 2-6 characters long
      decimals: 18,
    },
    cryptos: [
    ],

    blockExplorerUrls: ["https://etherscan.io"],
    scannName: "Etherscan",
  },
  {
    id: 21,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 137,
    name: "Polygon",
    sendChainId: 137,
    chainName: "Polygon",
    img: "polygon-24x24.svg",
    formClass: "polygon",

    server:{
      symbol:"POL",
      title:"Polygon"
    },

    hasContract: true,
    gasSenderAddress: "0xab52cD1975906c23d876995c74B84abB3Ab651f5",

    rpcUrls: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.maticvigil.com/",
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "DAI",
        symbol: "DAI",
        contractAddress: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "BUSD",
        symbol: "BUSD",
        contractAddress: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://polygonscan.com"],
    scannName: "Polygonscan",
  },
  {
    id: 22,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 250,
    name: "Fantom",
    sendChainId: 250,
    chainName: "Fantom",
    img: "fantom-24x24.svg",
    formClass: "fantom",

    server:{
      symbol:"FTM",
      title:"Fantom"
    },

    hasContract: true,
    gasSenderAddress: "0xae7c11BaadE21d177111ecDdF069d705BF6CF3Ea",

    rpcUrls: ["https://rpc.ftm.tools/", "https://rpcapi.fantom.network"],
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    cryptos: [
      {
        name: "DAI",
        symbol: "DAI",
        contractAddress: "0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://ftmscan.com"],
    scannName: "Fantomscan",
  },
  {
    id: 23,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 10,
    name: "Optimism",
    sendChainId: 10,
    chainName: "Optimism",
    img: "Optimism-24x24.svg",
    formClass: "optimism",

    server:{
      symbol:"OPT",
      title:"Optimism"
    },

    hasContract: true,
    gasSenderAddress: "0x144919282C59b8f3ea9143B1068ad15e672Dac92",

    rpcUrls: [
      "https://1rpc.io/op",
      "https://endpoints.omniatech.io/v1/op/mainnet/public",
      "https://mainnet.optimism.io/"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "DAI",
        symbol: "DAI",
        contractAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
    scannName: "Optimismscan",
  },
  {
    id: 24,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 56,
    name: "BNB",
    sendChainId: 56,
    chainName: "BNB",
    img: "bsc-24x24.svg",
    formClass: "bnb",

    server:{
      symbol:"BSC",
      title:"BSC"
    },

    hasContract: true,
    gasSenderAddress: "0x812B2df5C33f40fe4CF349056c26D42152DBe61b",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
      "https://bsc-dataseed.binance.org/",
      "https://bsc-dataseed1.defibit.io/",
      "https://bsc-dataseed1.ninicoin.io/",
    ],
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x55d398326f99059fF775485246999027B3197955",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "DAI",
        symbol: "DAI",
        contractAddress: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "BUSD",
        symbol: "BUSD",
        contractAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://bscscan.com"],
    scannName: "Bscscan",
  },
  {
    id: 25,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 43114,
    name: "Avalanche",
    sendChainId: 43114,
    chainName: "Avalanche",
    img: "avalanche-avax-logo 1.svg",
    formClass: "avalanche",

    server:{
      symbol:"AVA",
      title:"Avalanche"
    },

    hasContract: true,
    gasSenderAddress: "0xb8451d68582d6f708ee9363799A32530E8108590",

    rpcUrls: [
      "https://avalanche.blockpi.network/v1/rpc/public",
      "https://avalanche-evm.publicnode.com",
      "https://1rpc.io/avax/c",
    ],
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://snowtrace.io"],
    scannName: "Avalanchescan",
  },
  {
    id: 26,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 42161,
    name: "Arbitrum",
    sendChainId: 42161,
    chainName: "Arbitrum",
    img: "Arbitrum One-24x24.svg",
    formClass: "arbitrum",

    server:{
      symbol:"ARB",
      title:"Arbitrum"
    },

    hasContract: true,
    gasSenderAddress: "0x417a852E28599623415102fA2138B61eEb848124",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/arbitrum/one/public",
      "https://arbitrum.blockpi.network/v1/rpc/public",
      "https://arb1.arbitrum.io/rpc",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "DAI",
        symbol: "DAI",
        contractAddress: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://arbiscan.io"],
    scannName: "Arbitrumscan",
  },
  {
    id: 27,
    isInUse: true,
    isComingSoon: false,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 1313161554,
    name: "Aurora",
    sendChainId: 1313161554,
    chainName: "Aurora",
    img: "aurora-24x24.svg",
    formClass: "aurora",

    server:{
      symbol:"AUR",
      title:"Aurora"
    },

    hasContract: true,
    gasSenderAddress: "0x7d078dF1db3885E25c1E9B44Dc36C20016F7fa13",

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/aurora/mainnet/public",
      "https://mainnet.aurora.dev",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xB12BFcA5A55806AaF64E99521918A4bf0fC40802",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x4988a896b1227218e4a686fde5eabdcabd91571f",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://explorer.aurora.dev"],
    scannName: "Aurorascan",
  },
  {
    id: 28,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 42220,
    name: "Celo",
    sendChainId: 42220,
    chainName: "Celo",
    img: "Celo-24x24.svg",
    formClass: "celo",

    server:{
      symbol:"CEL",
      title:"Celo"
    },

    hasContract: true,
    gasSenderAddress: "0x4C9BFC8BA437e3fB7B746859827e162BBfDb51Ea",

    rpcUrls: [
      "https://forno.celo.org",
    ],
    nativeCurrency: {
      name: "CELO",
      symbol: "CELO",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x617f3112bf5397D0467D315cC709EF968D9ba546",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "USDC",
        symbol: "USDC",
        contractAddress: "0xef4229c8c3250C675F21BCefa42f58EfbfF6002a",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
      {
        name: "cUSD",
        symbol: "cUSD",
        contractAddress: "0x765DE816845861e75A25fCA122bb6898B8B1282a",
        decimals: 18,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://celoscan.io"],
    scannName: "Celoscan",
  },
  {
    id: 29,
    isInUse:true,
    isComingSoon: true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 288,
    name: "BOBA",
    sendChainId: 288,
    chainName: "BOBA",
    img: "boba-24x24.svg",
    formClass: "boba",

    server:{
      symbol:"BOB",
      title:"Boba"
    },

    hasContract: true,
    gasSenderAddress: "0xaAbbC38338266199ae346f57168B06ac95928f3f",

    rpcUrls: [
      "https://mainnet.boba.network",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d",
        decimals: 6,
        logoURI: null,
        isShowOnSend: true,
      },
    ],
    blockExplorerUrls: ["https://bobascan.com"],
    scannName: "Bobascan",
  },
  {
    id: 19,
    isInUse:true,
    isComingSoon: true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 1101,
    name: "Polygon ZkEVM",
    sendChainId: 1101,
    chainName: "BOBA",
    img: "zkevm.svg",
    formClass: "zkevm",

    server:{
      symbol:"PZK",
      title:"Polygon zkEVM"
    },

    hasContract: true,
    gasSenderAddress: "0x2da63B662823d39d8AC022281C8C66EBB03B0253",

    rpcUrls: [
      "https://rpc.ankr.com/polygon_zkevm",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    cryptos: [
      {
        name: "USDT",
        symbol: "USDT",
        contractAddress: "0x1E4a5963aBFD975d8c9021ce480b42188849D41d",
        decimals: 6,
        logoURI: null,
        isShowOnSend: false,
      },
    ],
    blockExplorerUrls: ["https://zkevm.polygonscan.com"],
    scannName: "Polygon zkEVMscan",
  },
  {
    id: 18,
    isInUse:true,
    wallets : [PROVIDER_METAMASK],
    chainType:CHAIN_TYPE_EVM,
    chainId: 32520,
    name: "Bitgert",
    sendChainId: 32520,
    chainName: "Bitgert",
    img: "bitgert-24x24.svg",
    formClass: "bitgert",

    server:{
      symbol:"BTG",
      title:"BitGert"
    },

    hasContract: true,
    gasSenderAddress: "0x2da63B662823d39d8AC022281C8C66EBB03B0253",

    rpcUrls: [
      "https://rpc.icecreamswap.com",
    ],
    nativeCurrency: {
      name: "Brise",
      symbol: "Brise",
      decimals: 18,
    },
    cryptos: [
    ],
    blockExplorerUrls: ["https://brisescan.com"],
    scannName: "Bitgertscan",
  },
];
