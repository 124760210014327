import { ethers } from "ethers";
import axios from "../app/axios";

export const URL_NETWORK_TVLS = 'statistic/network-tvls';
export const URL_NETWORK_WINNER = 'statistic/network-winner';
export const URL_TOP_CONTRACTS = 'statistic/top-contracts';
export const URL_TOP_ASSETS = 'statistic/popular-network-cryptos-by-volume';
export const URL_ACTIVE_BRIDGES = 'statistic/active-bridges';
export const URL_NETWORK_CRYPTO_DRIRECTIONS = 'statistic/network-cryptos-directions';
export const URL_PERIOD_TOTAL = 'statistic/period-total';

export function getNetworkWinner(date,prevDate){
  let url = URL_NETWORK_WINNER+'?date='+date+'&prevDate='+prevDate;
  return getDataByUrl(url);
}

export function getNetworkTvls(date,prevDate){
  let url = URL_NETWORK_TVLS+'?date='+date+'&prevDate='+prevDate;
  return getDataByUrl(url);
}

export function getPeriodTotal(date,prevDate){
  let url = URL_PERIOD_TOTAL+'?date='+date+'&prevDate='+prevDate;
  return getDataByUrl(url);
}

export function getTopContracts(date,prevDate){
  let url = URL_TOP_CONTRACTS+'?date='+date+'&prevDate='+prevDate;
  return getDataByUrl(url);
}

export function getTopAssets(date,prevDate){
  let url = URL_TOP_ASSETS+'?date='+date+'&prevDate='+prevDate;
  return getDataByUrl(url);
}

export function getActiveBridges(date,prevDate){
  let url = URL_ACTIVE_BRIDGES+'?date='+date+'&prevDate='+prevDate;
  return getDataByUrl(url);
}

export function getNetworkCryptoDirections(date,prevDate){
  let url = URL_NETWORK_CRYPTO_DRIRECTIONS+'?date='+date+'&prevDate='+prevDate;
  return getDataByUrl(url);
}

export async function getDataByUrl(url) {
  let statusInfo = {
    hasError: true,
    errorText: "",
    data: null,
  };

  try {
    let res = await axios.get(url);
    if (res && res.status == 200 && res.data) {
      statusInfo.data = res.data;
      statusInfo.hasError = false;
    } else {
      statusInfo.errorText = res.data?.message || res.data;
    }
  } catch (error) {
    statusInfo.errorText = error.response ? error.message : error;
  }
  return statusInfo;
}

