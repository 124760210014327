import React, { useEffect, useState } from "react";
import SiteBadge from "../../SiteBadge";
import { BRIDGE_TYPE_ASSET } from "../../../constants/Common";
import { numberWithSpaces } from "../../../utils/NumberUtil";

export default function AnalyticActiveBridges({ activeBridges }) {
  const [selectedTab, setSelectedtab] = useState(1);
  // const [bridges, setBridges] = useState([]);

  const tabs = [
    { id: 1, title: "Rating based on reviews" },
    { id: 2, title: "By number of interactions" },
  ];
  const testItem = {
    logo: "img/avalanche-round-logo.svg",
    title: "Avalanche Bridge",
    rating: "4.8",
    type: "Messaging",
    interactionsCount: "1 080 318",
    volume: "$16 000 000",
    overviewUrl: "",
    website: "",
  };
  // useEffect(() => {
  //   let items = [];
  //   for (let index = 0; index < 30; index++) {
  //     items.push(testItem);
  //   }
  //   setBridges(items);
  // }, []);
  const sortBridges = activeBridges.sort((a, b) => {
    let dif = 0;
    if (selectedTab == 1) {
      dif =
        parseFloat(b.rating || 0) - parseFloat(a.rating || 0) ||
        b.volumeInUsd - a.volumeInUsd;
    } else {
      dif =
        parseFloat(b.transactionCount) - parseFloat(a.transactionCount) ||
        b.volumeInUsd - a.volumeInUsd;
    }
    return dif;
  });

  return (
    <div className="analytics__solutions analytics__grid-item">
      <div className="analytics__grid-item-header">
        <div className="analytics__grid-item-title-cnt">
          <h3 className="analytics__grid-item-title">
            The most active cross-chain solutions
            <SiteBadge />
          </h3>
        </div>
        <div className="tab-switch">
          {tabs.map((tab) => (
            <label key={tab.id} className="tab-switch__item">
              <input
                className="tab-switch__input"
                name="rating"
                type="radio"
                defaultChecked={tab.id == selectedTab}
                onClick={() => setSelectedtab(tab.id)}
              />
              <span className="tab-switch__btn">{tab.title}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="analytics__grid-item-table-header">
        <div className="index-col header-col">
          <span>#</span>
        </div>
        <div className="name-col header-col">
          <span>Name</span>
        </div>
        <div className="rating-col header-col">
          <span>Rating</span>
          <button className="info-btn">
            <span className="tooltip tooltip_white">
              Rating is&nbsp;based on&nbsp;bridge scores from users
              of&nbsp;Chainspot.io
            </span>
          </button>
        </div>
        {/* <div className="type-col header-col">
          <span>Type</span>
          <button className="info-btn">
            <span className="tooltip tooltip_white">
              <span>
                Messaging bridges enable communication and data exchange between
                different blockchains or&nbsp;DApps.
              </span>
              <span>
                Asset bridges facilitate the transfer of&nbsp;digital assets
                between various blockchains.
              </span>
            </span>
          </button>
        </div> */}
        <div className="interactions-col header-col">
          <span>Interactions</span>
          <button className="info-btn">
            <span className="tooltip tooltip_white">
              The number of&nbsp;interactions (transfers of&nbsp;tokens, assets,
              data, etc.) with smart contracts of&nbsp;the bridge.
            </span>
          </button>
        </div>
        <div className="volume-col header-col">
          <span>Volume</span>
          <button className="info-btn">
            <span className="tooltip tooltip_white">
              Total value of&nbsp;assets transacted through the bridge.
            </span>
          </button>
        </div>
        <div className="cta-col header-col"></div>
      </div>

      <div className="analytics__table-cnt">
        <div className="analytics__solutions-table analytics__grid-item-table">
          {sortBridges.map((bridge, index) => (
            <div key={bridge.id} className="analytics__grid-item-table-row">
              <div className="index-col table-row">
                <span>{index + 1}</span>
              </div>
              <div className="name-col table-row">
                <img src={bridge.logoUrl} width="26" height="26" alt="" />
                <span>{bridge.title}</span>
              </div>
              <div className="rating-col table-row">
                <img src="/img/rating-icon.svg" width="18" height="17" alt="" />
                <span>{bridge.rating}</span>
              </div>
              {/* <div className="type-col table-row">
                <span>{bridge.type || BRIDGE_TYPE_ASSET}</span>
              </div> */}
              <div className="interactions-col table-row">
                <img
                  src="/img/interactions-icon.svg"
                  width="20"
                  height="20"
                  alt=""
                />
                <span>{numberWithSpaces(bridge.transactionCount)}</span>
              </div>
              <div className="volume-col table-row">
                <span>${numberWithSpaces(parseInt(bridge.volumeInUsd))}</span>
              </div>
              <div className="cta-col table-row">
                <a
                  className="btn-table btn-table_secondary"
                  href={bridge.portalUrl}
                  target="_blank"
                >
                  Overview
                </a>
                <a
                  className="btn-table btn-table_primary"
                  href={bridge.website}
                  target="_blank"
                >
                  Visit website
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
