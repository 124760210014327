import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMenuItems } from "../../../data/menu";
import { HeaderActions } from "./HeaderActions";
import { MenuList } from "./MenuList";
import MobileMenuButton from "./MobileMenuButton";

function TopMenu() {
  const links = getMenuItems(false);

  const activeClass = (route) => {
    return window.location.pathname === route ? "active" : null;
  };
  let dispatch = useDispatch();

  return (
    <>
      <header className={`header `}>
        <MenuList links={links} navClass={"main-menu_header"} />
        <NavLink className={"logo-link"} to="/">
          <img className="logo" src={"/img/chainspot-logo.png"} alt="" />
        </NavLink>
        <HeaderActions />
        <MobileMenuButton />
      </header>
    </>
  );
}
export default TopMenu;
