import React from "react";
import SiteBadge from "../../SiteBadge";
import { formatPercentage } from "../../../utils/NumberUtil";

export default function AnalyticNetworkWinner({ winner }) {
  return (
    <div className="analytics__winner analytics__grid-item">
      <div className="analytics__winner-header analytics__grid-item-header">
        <div className="analytics__grid-item-title-cnt">
          <h3 className="analytics__grid-item-title">
            Network winner of the week
            <SiteBadge />
          </h3>
        </div>
      </div>
      <div className="analytics__winner-content">
        {winner && (
          <div className="analytics__winner-token ethereum">
            <img
              src="/img/ethereum-icon-big.png"
              width="49"
              height="71"
              alt=""
            />
            <span>{winner.network?.title}</span>
          </div>
        )}
      </div>
      {winner && (
        <div className="analytics__winner-total">
          <span className="analytics__winner-total-title">Total TVL</span>
          <div className="analytics__winner-total-value">
            <span>${winner.tvlInUsdAbbr}</span>
            <span className={"analytics__winner-total-grow " + (parseFloat(winner.tvlChangePercent) > 0 ? 'up' : 'down')}>
              {formatPercentage(winner.tvlChangePercent)}%
            </span>
          </div>
          <span className="analytics__winner-total-subscr">
            Compared to ${winner.prevTvlInUsdAbbr} last {winner.period}
          </span>
        </div>
      )}
    </div>
  );
}
