import React from "react";
import { SITE_NAME } from "../constants/General";

export default function SiteBadge() {
  return (
    <>
      {" "}
      <div className="chainspot-badge">
        <img src="/img/chainspot-icon.png" width="19" height="19" alt="" />
        <span>{SITE_NAME}</span>
      </div>
    </>
  );
}
