import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getMenuItems } from "../../../data/menu";
import { DISCORD_ADDRESS, TELEGRAM_GROUP, TWITTER_LINK } from "../../../constants/SocialLinks";
import WalletConnectButton from "../../../components/WalletConnectButton/WalletConnectButton";
import { ymTgInfo, ymTwitterInfo } from "../../../utils/YandexUtl";

export function HeaderActions() {
  const [links] = useState(getMenuItems(false));

  const activeClass = (route) => {
    return window.location.pathname === route ? "active" : null;
  };
  let dispatch = useDispatch();

  return (
    <>
      <div className="header__actions">
        <div className="header__socials">
          <a href={TELEGRAM_GROUP} target={"_blank"} onClick={() => ymTgInfo()}>
            <img src="/img/telegram-icon.svg" alt="" />
          </a>
          <a href={TWITTER_LINK} target={"_blank"} onClick={() => ymTwitterInfo()}>
            <img src="/img/twitter-icon.svg" alt="" />
          </a>
          <a href={DISCORD_ADDRESS} target={"_blank"} onClick={() => ymTwitterInfo()}>
            <img src="/img/discord-icon.svg" alt="" />
          </a>
        </div>
        <WalletConnectButton />
      </div>
    </>
  );
}
