import React from "react";
import { MAIL_ADDRESS } from "../../constants/SocialLinks";
export function Socials() {
  return (
    <div className="footer__socials">
      <div className="footer__email">
        <span>Talk to us</span>
        <a href={MAIL_ADDRESS} className="white-link">
          {MAIL_ADDRESS}
        </a>
      </div>
      <a href="" className="btn btn_white">
        Contact us
      </a>
    </div>
  );
}
