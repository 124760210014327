import React from "react";
import AnalyticDiagram from "./AnalyticDiagram";
import AnalyticGeneralStat from "./AnalyticGeneralStat";
import AnalyticTopSection from "./AnalyticTopSection";

export default function AnalyticTop({
  selectedPeriod,
  setFilterPeriod,
  topStat,
}) {
  return (
    <div className="data-v">
      <div className="wrap">
        <AnalyticTopSection
          selectedPeriod={selectedPeriod}
          setFilterPeriod={setFilterPeriod}
        />
        <AnalyticDiagram />
        <AnalyticGeneralStat
          topStat={topStat}
          selectedPeriod={selectedPeriod}
        />
      </div>
    </div>
  );
}
