export const formatCash = (num) => {
  let n = parseInt(num);
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatPercentage = (x) => {
  return parseFloat(x).toFixed(2);
};

export const formatTvlDif = (x) => {
  return parseFloat(x).toFixed(2);
};

export const formatTvl = (x) => {
  return parseFloat(x).toFixed(2);
};
