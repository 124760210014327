import React, { useEffect, useState } from "react";
import SiteBadge from "../../SiteBadge";
import { formatCash } from "../../../utils/NumberUtil";

export default function AnalyticTopContracts({ topContracts }) {
  const [selectedTab, setSelectedtab] = useState(1);
  const [openTogler, setOpenTogler] = useState(false);
  const tabs = [
    { id: 1, title: "By number of transfers" },
    { id: 2, title: "By transfer amount" },
  ];
  const activeTab = tabs.find((v) => v.id == selectedTab);
  const changeTab = (tab) => {
    setSelectedtab(tab.id);
    setOpenTogler(false);
  };
  const maxCount = 10;

  const onlyActiveContracts = topContracts
    .filter((v) => v.volumeInUsd != 0 && v.transactionCount != 0)
    .sort((a, b) => {
      let dif = 0;
      if (selectedTab == 1) {
        dif = parseFloat(b.transactionCount) - parseFloat(a.transactionCount);
      } else {
        dif = parseFloat(b.volumeInUsd) - parseFloat(a.volumeInUsd);
      }
      return dif;
    })
    .slice(0, maxCount);

  return (
    <div className="analytics__top-addresses analytics__grid-item">
      <div className="analytics__grid-item-header">
        <div className="analytics__grid-item-title-cnt">
          <h3 className="analytics__grid-item-title">
            Most active bridges’ smart contracts
            <SiteBadge />
          </h3>
        </div>
        <div className={"select-btn " + (openTogler ? "active" : "")}>
          <button
            className="select-btn__button"
            onClick={() => setOpenTogler(!openTogler)}
          >
            {activeTab.title}
          </button>
          <div className="select-btn__dropdown">
            {tabs.map((tab) => (
              <span
                key={tab.id}
                className={
                  "select-btn__item " +
                  (tab.id == selectedTab ? "select-btn__item_selected" : "")
                }
                onClick={() => changeTab(tab)}
              >
                {tab.title}
              </span>
            ))}
          </div>
        </div>
        <div className="tab-switch">
          {tabs.map((tab) => (
            <label key={tab.id} className="tab-switch__item">
              <input
                className="tab-switch__input"
                name="assets"
                type="radio"
                defaultChecked={tab.id == selectedTab}
                onClick={() => setSelectedtab(tab.id)}
              />
              <span className="tab-switch__btn">{tab.title}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="analytics__top-addresses-table analytics__grid-item-table">
        <div className="analytics__grid-item-table-header">
          <div className="index-col header-col">
            <span>#</span>
          </div>
          <div className="address-col header-col">
            <span>Contract Address</span>
          </div>
          <div className="trans-num-col header-col">
            <span>Number of transfers</span>
          </div>
          <div className="amount-col header-col header-col_right">
            <span>Transfer amount</span>
          </div>
        </div>

        {onlyActiveContracts.map((contractInfo, index) => (
          <div key={contractInfo.id} className="analytics__grid-item-table-row">
            <div className="index-col table-row">
              <span>{index + 1}</span>
            </div>
            <div className="address-col table-row">
              <span>
                {contractInfo.address && contractInfo.address.length > 21
                  ? contractInfo.address.substr(0, 21) + "..."
                  : contractInfo.address}
              </span>
            </div>
            <div className="trans-num-col table-row">
              <span>{contractInfo.transactionCount}</span>
            </div>
            <div className="amount-col table-row">
              <span>${formatCash(contractInfo.volumeInUsd)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
