import React from "react";
import { PERIOD_FILTERS } from "../../../constants/FormConstants";

export default function AnalyticPeriodFilter({
  selectedPeriod,
  setFilterPeriod,
  name='timeline'
}) {

  return (
    <>
      {PERIOD_FILTERS.map((period) => (
        <label key={period.key} className="multi-switch__item">
          <input
            type="radio"
            name={name}
            // checked={selectedPeriod === period.key}
            defaultChecked={selectedPeriod === period.key}
            onClick={() => setFilterPeriod(period.key)}
          />
          <span>{period.title}</span>
        </label>
      ))}
    </>
  );
}
