import React from "react";
// import GasWelcomePopup from "../../../components/Gas/GasWelcomePopup";
// import ConnectWalletWaiting from "../../../components/Popups/ConnectWalletWaiting";
// import SwitchNetworkPopup from "../../../components/Popups/SwitchNetworkPopup";
import NotificationPopup from "../../Popups/NotificationPopup";
// import { connectWalletuseState } from "react";
import ChooseWallet from "../ChooseWallet/ChooseWallet";
// import TvmConnectPopup from "../../../components/Popups/TvmConnectPopup";
// import WalletInstallPopup from "../../../components/Popups/WalletInstallPopup";
// import CookiePopup from "../../../components/Popups/CookiePopup";
// import DisclaimerPopup from "../../../components/Popups/DisclaimerPopup";

function PopupBlock() {
  return (
    <>
      {/* <DisconnectMetamask /> */}
      <ChooseWallet />
      {/* <WalletInfoDialog /> */}
      {/* <TermsAgreement/> */}
      <NotificationPopup/>
      {/* <ConnectWalletWaiting/> */}
      {/* <SwitchNetworkPopup/> */}
      {/* <GasWelcomePopup/> */}
      {/* <TvmConnectPopup/> */}
      {/* <WalletInstallPopup/> */}
      {/* <CookiePopup/> */}
      {/* <DisclaimerPopup/> */}
    </>
  );
}
export default PopupBlock;
