import React, { useEffect, useState } from "react";
import { PERIOD_FILTERS } from "../../../constants/FormConstants";
import { formatCash } from "../../../utils/NumberUtil";
import { getNetworkImageByChainId, getNetworkMiddleImageByChainId } from "../../../utils/NetworkUtil";

export default function AnalyticGeneralStat({ topStat, selectedPeriod }) {
  const [topGainer, setTopGainer] = useState(null);
  const [topLoser, setTopLoser] = useState(null);

  useEffect(() => {
    setToppers();
  }, [topStat]);

  const setToppers = () => {
    let networkWinnerIndex = -1;
    let networkLoserIndex = -1;
    let looseTvlChange = 0;
    let winTvlChange = 0;

    topStat.networkTvls.forEach((network, index) => {
      let tvlChangePercent = parseFloat(network.tvlChangePercent);
      if (tvlChangePercent > winTvlChange && tvlChangePercent > 0) {
        winTvlChange = tvlChangePercent;
        networkWinnerIndex = index;
      }
      if (
        Math.abs(tvlChangePercent) > looseTvlChange &&
        tvlChangePercent <= 0
      ) {
        looseTvlChange = tvlChangePercent;
        networkLoserIndex = index;
      }
    });

    if (networkLoserIndex == -1) {
      setTopLoser(null);
    } else {
      setTopLoser(topStat.networkTvls[networkLoserIndex]);
    }

    if (networkWinnerIndex == -1) {
      setTopGainer(null);
    } else {
      setTopGainer(topStat.networkTvls[networkWinnerIndex]);
    }
  };

  const selectedPeriodItem = PERIOD_FILTERS.find(
    (v) => v.key == selectedPeriod
  );

  return (
    <section className="data-v__info">
      <div className="data-v__info-top data-v__info-top_gainer">
        <span className="data-v__info-top-title data-v__info-title">
          Top gainer
        </span>
        {topGainer && (
          <>
            <div className="data-v__info-top-icon data-v__icon-cnt">
              <img src={getNetworkMiddleImageByChainId(topGainer.network.chainId)} alt="" />
            </div>
            <span className="data-v__info-top-network">
              {topGainer.network.title}
            </span>
            <div className="data-v__info-top-value">
              <span>${formatCash(topGainer.tvlInUsd)}</span>
              <span className="data-v__info-top-grow up">
                {topGainer.tvlChangePercent}%
              </span>
            </div>
            <span className="data-v__info-top-subscr">
              Compared to ${formatCash(topGainer.prevTvlInUsd)} last{" "}
              {selectedPeriodItem.type}
            </span>
          </>
        )}
      </div>

      <div className="data-v__info-general">
        <div className="data-v__info-general-item">
          <div className="data-v__info-general-icon data-v__icon-cnt">
            <img src="/img/token-of-bridges-icon.svg" alt="" />
          </div>
          <span className="data-v__info-general-title data-v__info-title">
            Bridges under monitoring
          </span>
          <span className="data-v__info-value">
            {topStat.totalStat && topStat.totalStat.bridgesCount}
          </span>
        </div>
        {/* <div className="data-v__info-general-item">
          <div className="data-v__info-general-icon data-v__icon-cnt">
            <img src="/img/active-users-icon.svg" alt="" />
          </div>
          <span className="data-v__info-general-title data-v__info-title">
            Active users
          </span>
          <span className="data-v__info-value">
            {" "}
            {topStat.totalStat && topStat.totalStat.activeUsersCount}
          </span>
        </div> */}
        <div className="data-v__info-general-item">
          <div className="data-v__info-general-icon data-v__icon-cnt">
            <img src="/img/transfer-amounts-icon.svg" alt="" />
          </div>
          <span className="data-v__info-general-title data-v__info-title">
            Number of transactions
          </span>
          <span className="data-v__info-value">
            {topStat.totalStat && formatCash(topStat.totalStat.transactionCount)}
          </span>
        </div>
      </div>

      <div className="data-v__info-top data-v__info-top_loser">
        <span className="data-v__info-top-title data-v__info-title">
          Top loser
        </span>
        {topLoser && (
          <>
            <div className="data-v__info-top-icon data-v__icon-cnt">
              <img src={getNetworkMiddleImageByChainId(topLoser.network.chainId)} alt="" />
            </div>
            <span className="data-v__info-top-network">
              {topLoser.network.title}
            </span>
            <div className="data-v__info-top-value">
              <span>${formatCash(topLoser.tvlInUsd)}</span>
              <span className="data-v__info-top-grow down">
                {topLoser.tvlChangePercent}%
              </span>
            </div>
            <span className="data-v__info-top-subscr">
              Compared to ${formatCash(topLoser.prevTvlInUsd)} last{" "}
              {selectedPeriodItem.type}
            </span>
          </>
        )}
      </div>
    </section>
  );
}
