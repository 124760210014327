import React, { useEffect, useState } from "react";
import AnalyticTop from "./AnalyticTop/AnalyticTop";
import AnalyticGridHeader from "./AnalyticGrid/AnalyticGridHeader";
import AnalyticNetworkWinner from "./AnalyticGrid/AnalyticNetworkWinner";
import AnalyticRebalanceLiquidity from "./AnalyticGrid/AnalyticRebalanceLiquidity";
import AnalyticMostPopularAssets from "./AnalyticGrid/AnalyticMostPopularAssets";
import AnalyticAmountTransfer from "./AnalyticGrid/AnalyticAmountTransfer";
import AnalyticTopContracts from "./AnalyticGrid/AnalyticTopContracts";
import AnalyticActiveBridges from "./AnalyticGrid/AnalyticActiveBridges";
import { LoadGridData } from "../../hooks/LoadGridData";
import { LoadTopData } from "../../hooks/LoadTopData";
export default function AnalyticMain() {
  const [selectedPeriod, setFilterPeriod] = useState(1);
  const [gridSelectedPeriod, setGridFilterPeriod] = useState(1);

  const gridStatisticData = LoadGridData(gridSelectedPeriod);
  const topStat = LoadTopData(selectedPeriod);
  return (
    <>
      <AnalyticTop
        selectedPeriod={selectedPeriod}
        setFilterPeriod={setFilterPeriod}
        topStat={topStat}
      />
      <div className="analytics">
        <div className="wrap">
          <AnalyticGridHeader
            selectedPeriod={gridSelectedPeriod}
            setFilterPeriod={setGridFilterPeriod}
          />
          <div className="analytics__grid">
            <AnalyticNetworkWinner winner={gridStatisticData.networkWinner} />
            <AnalyticRebalanceLiquidity
              networks={gridStatisticData.networkTvls}
            />
            <AnalyticMostPopularAssets
              popularAssets={gridStatisticData.popularAssets}
            />
            <AnalyticAmountTransfer
              networkCryptoDirections={
                gridStatisticData.networkCryptoDirections
              }
            />
            <AnalyticTopContracts
              topContracts={gridStatisticData.topContracts}
            />
            <AnalyticActiveBridges
              activeBridges={gridStatisticData.activeBridges}
            />
          </div>
        </div>
      </div>
    </>
  );
}
