import { useEffect, useRef, useState } from "react";
import { PERIOD_FILTERS } from "../constants/FormConstants";
import moment from "moment";
import {
  getActiveBridges,
  getNetworkCryptoDirections,
  getNetworkTvls,
  getNetworkWinner,
  getTopAssets,
  getTopContracts,
} from "../utils/TransferApiUtil";
import { formatCash } from "../utils/NumberUtil";

export function LoadGridData(gridSelectedPeriod) {
  const [networkWinner, setNetworkWinner] = useState(null);
  const [networkTvls, setNetworkTvls] = useState([]);
  const [topContracts, setTopContracts] = useState([]);
  const [popularAssets, setPopularAssets] = useState([]);
  const [activeBridges, setActiveBridges] = useState([]);
  const [networkCryptoDirections, setNetworkCryptoDirections] = useState([]);

  useEffect(() => {
    console.log(gridSelectedPeriod, "gridSelectedPeriod");
    let dates = getPeriodDates(gridSelectedPeriod);
    loadNetworkWinner(dates);
    loadNetworkTvls(dates);
    loadTopContracts(dates);
    loadPopularAssets(dates);
    loadActiveBridges(dates);
    loadNetworkCryptoDirections(dates);
  }, [gridSelectedPeriod]);

  const getPeriodDates = (gridSelectedPeriod) => {
    let period = PERIOD_FILTERS.find((v) => v.key == gridSelectedPeriod);
    let curDate = moment().format("YYYY-MM-DD");
    let prevDate = moment()
      .subtract(period.num, period.type)
      .format("YYYY-MM-DD");
    return { curDate, prevDate, period };
  };

  const loadNetworkWinner = async (dates) => {
    let data = await getNetworkWinner(dates.curDate, dates.prevDate);
    if (!data.hasError) {
      data.data.period = dates.period.type;
      data.data.tvlInUsdAbbr = formatCash(data.data.tvlInUsd);
      data.data.prevTvlInUsdAbbr = formatCash(data.data.prevTvlInUsd);
    }
    setNetworkWinner(data.hasError ? null : data.data);
  };

  const loadNetworkTvls = async (dates) => {
    let data = await getNetworkTvls(dates.curDate, dates.prevDate);
    let networkTvls = [];
    if (!data.hasError && data.data) {
      Object.keys(data.data).forEach((key) => {
        let obj = { ...data.data[key] };
        obj.tvlInUsdAbr = formatCash(obj.tvlInUsd);
        obj.tvlDif = formatCash(Math.abs(obj.tvlInUsd - obj.prevTvlInUsd));
        networkTvls.push(obj);
      });
    }
    networkTvls.sort((a, b) => parseFloat(a.tvlInUsd) - parseFloat(b.tvlInUsd));
    setNetworkTvls(networkTvls);
  };

  const loadTopContracts = async (dates) => {
    let data = await getTopContracts(dates.curDate, dates.prevDate);
    let topContracts = [];
    if (!data.hasError && data.data) {
      topContracts = data.data;
    }
    setTopContracts(topContracts);
  };

  const loadPopularAssets = async (dates) => {
    let data = await getTopAssets(dates.curDate, dates.prevDate);
    let popularAssets = [];
    if (!data.hasError && data.data) {
      popularAssets = data.data;
    }
    setPopularAssets(popularAssets);
  };

  const loadActiveBridges = async (dates) => {
    let data = await getActiveBridges(dates.curDate, dates.prevDate);
    let activeBridges = [];
    if (!data.hasError && data.data) {
      activeBridges = data.data;
    }
    setActiveBridges(activeBridges);
  };

  const loadNetworkCryptoDirections = async (dates) => {
    let data = await getNetworkCryptoDirections(dates.curDate, dates.prevDate);
    let networkCryptos = [];
    if (!data.hasError && data.data) {
      networkCryptos = data.data;
    }
    setNetworkCryptoDirections(networkCryptos);
  };

  return {
    networkWinner,
    networkTvls,
    topContracts,
    popularAssets,
    activeBridges,
    networkCryptoDirections,
  };
}
