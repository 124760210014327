import React from "react";
import SiteBadge from "../../SiteBadge";
import { getNetworkImageByChainId } from "../../../utils/NetworkUtil";
import {
  formatCash,
  formatPercentage,
  formatTvlDif,
  numberWithSpaces,
} from "../../../utils/NumberUtil";

export default function AnalyticRebalanceLiquidity({ networks }) {
  return (
    <div className="analytics__rebalancing analytics__grid-item">
      <div className="analytics__rebalancing-header analytics__grid-item-header">
        <div className="analytics__grid-item-title-cnt">
          <h3 className="analytics__grid-item-title">
            Liquidity of bridges between chains
            <SiteBadge />
          </h3>
        </div>
      </div>
      <div className="analytics__rebalancing-table analytics__grid-item-table">
        <div className="analytics__grid-item-table-header">
          <div className="index-col header-col">
            <span>#</span>
          </div>
          <div className="network-col header-col">
            <span>Network</span>
          </div>
          <div className="tvl-col header-col">
            <span>
              Change in TVL
              <button className="info-btn">
                <span className="tooltip tooltip_white">
                  The difference in&nbsp;the&nbsp;total value of&nbsp;assets
                  locked in&nbsp;the&nbsp;network over the&nbsp;selected period.
                </span>
              </button>
            </span>
          </div>
          <div className="avg-col header-col">
            <span>
              Avg. chg.
              <button className="info-btn">
                <span className="tooltip tooltip_white">
                  TVL percentage change for the&nbsp;selected period.
                </span>
              </button>
            </span>
          </div>
          <div className="volume-col header-col header-col_right">
            <span>
              Volume
              <button className="info-btn">
                <span className="tooltip tooltip_white">
                  Volume of&nbsp;transfers in&nbsp;the&nbsp;network for
                  the&nbsp;selected period.
                </span>
              </button>
            </span>
          </div>
        </div>
        {networks.map((networkInfo, index) => (
          <div key={index} className="analytics__grid-item-table-row">
            <div className="index-col table-row">
              <span>{index + 1}</span>
            </div>
            <div className="network-col table-row">
              <div className="token-cnt">
                <div className="token-cnt__icon icon-cnt">
                  <img
                    className="token-cnt__image"
                    src={getNetworkImageByChainId(networkInfo.network.chainId)}
                    alt=""
                  />
                  <div className="token-cnt__icon-bg"></div>
                </div>
                <div className="token-cnt__title">
                  <span className="token-cnt__name">
                    {networkInfo.network.title}
                  </span>
                  <span className="token-cnt__value">
                    ${networkInfo.tvlInUsdAbr}
                  </span>
                </div>
              </div>
            </div>
            <div className="tvl-col table-row">
              <span>
                <img
                  src={
                    "/img/" +
                    (networkInfo.tvlChangePercent > 0
                      ? "grow-arrow.svg"
                      : "down-arrow.svg")
                  }
                  alt=""
                />{" "}
                ${networkInfo.tvlDif}
              </span>
            </div>
            <div
              className={
                "avg-col table-row " +
                (networkInfo.tvlChangePercent > 0 ? "up" : "down")
              }
            >
              <span>
                <img
                  src={
                    "/img/" +
                    (networkInfo.tvlChangePercent > 0
                      ? "grow-arrow.svg"
                      : "down-arrow.svg")
                  }
                  alt=""
                />{" "}
                {formatPercentage(networkInfo.tvlChangePercent)}%
              </span>
            </div>
            <div className="volume-col table-row">
              <span>
                ${formatCash(networkInfo.volumeInUsd)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
