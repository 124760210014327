import { CHAIN_TYPE_TVM } from "../constants/ChainTypes";
import { IS_DEV } from "../constants/GasStationParams";
import { DEF_MAINNET_CHAINS } from "../constants/ProdNetworks";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_TRON,
  PROVIDER_VENOM,
} from "../constants/ProviderTypes";
import { DEF_TESTNET_CHAINS } from "../constants/TestNetworks";
import { isEverWallet, isEvmWallet, isVenomWallet } from "./WalletUtil";

export function getNetworks() {
  let networks = DEF_MAINNET_CHAINS;
  return networks;
}

export function getContractNetworks() {
  return getNetworks().filter((v) => v.hasContract);
}

const networkImageFolder = "/images/network-logos/";

export function getNetworkImageByChainId(networkChainId) {
  let item = getNetworks().find((v) => v.chainId == networkChainId);
  return item ? networkImageFolder + item.img : "";
}

export function getNetworkMiddleImageByChainId(networkChainId) {
  let item = getNetworks().find((v) => v.chainId == networkChainId);
  return item?.middleImg;
}

export function getNetworkSymbolByChainId(networkChainId) {
  let item = getNetworks().find((v) => v.chainId == networkChainId);
  return item ? item.nativeCurrency.symbol : "";
}

export function getNetworkByChainIdAndWallet(
  chainId,
  walletType,
  connection = null
) {
  let networks = getNetworks();
  return networks.find(
    (v) =>
      v.wallets.includes(walletType) &&
      v.chainId == chainId &&
      (connection == null || v.connection == connection)
  );
}

export function getNetworkImage(network) {
  return network?.img
    ? networkImageFolder + network.img
    : "/img/currencies/ethereum.png";
}

export function getNetworkType(network) {
  return network.chainType;
}

export function getNetworkSymbol(network) {
  return network.nativeCurrency.symbol;
}

export function getNetworkName(network) {
  return network?.name || "Ethereum";
}

export function getNetworkExplorer(network) {
  return network.blockExplorerUrls[0];
}

export function getNetworkTransactionUrl(network, transactionHash) {
  let baseUrl = getNetworkExplorer(network);
  return (
    baseUrl +
    "/" +
    (network.chainType == CHAIN_TYPE_TVM ? "transactions" : "tx") +
    "/" +
    transactionHash
  );
}

export function getNetworkTransactionListUrl(network, address) {
  let baseUrl = getNetworkExplorer(network);
  if (network.chainType == CHAIN_TYPE_TVM) {
    return baseUrl + "/" + "accounts/" + address + "/transactions";
  }
  return baseUrl + "/address/" + address;
}

export function getExploreName(network) {
  return network.scannName;
}

export function getNetworkTokenSymbol(networkChainId) {
  let item = getNetworks().find((v) => v.chainId == networkChainId);
  return item ? item.nativeCurrency.symbol : "";
}

export function getTokenImg(token) {
  let tokens = [
    { symbol: "ETH", logo: "/img/ethereum-small-icon.png" },
    { symbol: "USDT", logo: "/img/tokens-logos/token.png" },
    {
      symbol: "MATIC",
      logo: "https://static.debank.com/image/bsc_token/logo_url/0xcc42724c6683b7e57334c4e856f4c9965ed682bd/6f5a6b6f0732a7a235131bd7804d357c.png",
    },
    {
      symbol: "SRM",
      logo: "https://static.debank.com/image/eth_token/logo_url/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff/6458883add3412a15a0ace2fb0e6c780.png",
    },
    {
      symbol: "USDC",
      logo: "https://static.debank.com/image/coin/logo_url/usdc/e87790bfe0b3f2ea855dc29069b38818.png",
    },
    {
      symbol: "DAI",
      logo: "https://static.debank.com/image/matic_token/logo_url/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063/549c4205dbb199f1b8b03af783f35e71.png",
    },
    {
      symbol: "BUSD",
      logo: "https://static.debank.com/image/bsc_token/logo_url/0xe9e7cea3dedca5984780bafc599bd69add087d56/588ad5043e23b6c46aeda945852c3273.png",
    },
    {
      symbol: "WETH",
      logo: "https://svgshare.com/i/Bff.svg",
    },
    {
      symbol: "WBTC",
      logo: "https://static.debank.com/image/eth_token/logo_url/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599/d3c52e7c7449afa8bd4fad1c93f50d93.png",
    },
  ];
  let tokenData = tokens.find((v) => v.symbol == token.symbol);
  return token && token.logoURI
    ? token.logoURI
    : tokenData
    ? tokenData.logo
    : "/img/tokens-list-logos/omg.png";
}

export const WalletList = [
  {
    title: "Metamask",
    key: PROVIDER_METAMASK,
    img: "./img/metamask-logo.png",
    imgLarge: "./images/metamask-logo-large.png",
    smallImg: "./img/metamask-wallet-icon.png",
    disabled: false,
    isInstalled: () => {
      return window.ethereum && window.ethereum.isMetaMask;
    },
    installUrl:
      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
  },
  {
    title: "Ever Wallet",
    key: PROVIDER_EVERWALLET,
    img: "./img/ever-wallet-icon.png",
    imgLarge: "",
    smallImg: "./img/ever-wallet-icon.png",
    disabled: false,
    isInstalled: () => {
      return true;
    },
    installUrl:
      "https://chrome.google.com/webstore/detail/ever-wallet/cgeeodpfagjceefieflmdfphplkenlfk",
  },
  {
    title: "Venom",
    key: PROVIDER_VENOM,
    img: "./img/venom-wallet.png",
    imgLarge: "",
    smallImg: "./img/venom-wallet.png",
    disabled: false,
    isInstalled: () => {
      return true;
    },
    installUrl:
      "https://chrome.google.com/webstore/detail/venom-wallet/ojggmchlghnjlapmfbnjholfjkiidbch",
  },
  {
    title: "TronLink",
    key: PROVIDER_TRON,
    img: "./img/tron-wallet-icon.png",
    imgLarge: "https://tron.network/static/images/header/logo-red.svg",
    smallImg: "./img/tron-wallet-icon.png",
    disabled: true,
    isInstalled: () => {
      return true;
    },
    installUrl:
      "https://chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec",
  },
];

export function getWalletServiceImg(key) {
  let item = WalletList.find((v) => v.key == key);
  return item ? item.imgLarge : "";
}

export function isCorrectAddress(address, network) {
  if (!address) {
    return false;
  }
  let isCorrect = false;
  if (isEvmWallet(network)) {
    isCorrect = address.substr(0, 2) == "0x" && address.length == 42;
  }
  if (isEverWallet(network) || isVenomWallet(network)) {
    isCorrect = address.substr(0, 2) == "0:" && address.length == 66;
  }
  return isCorrect;
}
