import { useEffect, useRef, useState } from "react";
import { PERIOD_FILTERS } from "../constants/FormConstants";
import moment from "moment";
import { getNetworkTvls, getPeriodTotal } from "../utils/TransferApiUtil";
import { formatCash } from "../utils/NumberUtil";

export function LoadTopData(selectedPeriod) {
  const [networkTvls, setNetworkTvls] = useState([]);
  const [totalStat, setTotalStat] = useState([]);

  useEffect(() => {
    let dates = getPeriodDates(selectedPeriod);
    loadNetworkTvls(dates);
    loadTotals(dates);
  }, [selectedPeriod]);

  const getPeriodDates = (gridSelectedPeriod) => {
    let period = PERIOD_FILTERS.find((v) => v.key == gridSelectedPeriod);
    let curDate = moment().format("YYYY-MM-DD");
    let prevDate = moment()
      .subtract(period.num, period.type)
      .format("YYYY-MM-DD");
    return { curDate, prevDate, period };
  };

  const loadNetworkTvls = async (dates) => {
    let data = await getNetworkTvls(dates.curDate, dates.prevDate);
    let networkTvls = [];
    if (!data.hasError && data.data) {
      Object.keys(data.data).forEach((key) => {
        let obj = { ...data.data[key] };
        obj.tvlInUsdAbr = formatCash(obj.tvlInUsd);
        obj.tvlDif = formatCash(Math.abs(obj.tvlInUsd - obj.prevTvlInUsd));
        networkTvls.push(obj);
      });
    }
    networkTvls.sort((a, b) => parseFloat(a.tvlInUsd) - parseFloat(b.tvlInUsd));
    setNetworkTvls(networkTvls);
  };

  const loadTotals = async (dates) => {
    let data = await getPeriodTotal(dates.curDate, dates.prevDate);
    if (!data.hasError && data.data) {
      setTotalStat(data.data);
    } else {
      setTotalStat(null);
    }
  };

  return {
    networkTvls,
    totalStat,
  };
}
