import React from "react";
import AnalyticMain from "../../components/Analytic/AnalyticMain";

export default function AnalyticPage() {
  return (
    <main className="main main_nobg">
      <AnalyticMain />
    </main>
  );
}
