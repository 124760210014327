import React, { useEffect } from "react";
import SiteBadge from "../../SiteBadge";
import Swiper, { Navigation } from "swiper";
import {
  getNetworkImageByChainId,
  getNetworkSymbolByChainId,
  getTokenImg,
} from "../../../utils/NetworkUtil";
import { formatCash, formatTvl } from "../../../utils/NumberUtil";

export default function AnalyticMostPopularAssets({ popularAssets }) {
  useEffect(() => {
    makeSwiper();
  }, [popularAssets]);

  const makeSwiper = () => {
    const swiper = new Swiper(".most-pop-slider", {
      modules: [Navigation],
      spaceBetween: 40,
      slidesPerView: "auto",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      breakpoints: {
        769: {
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
      },
    });
  };

  const maxCount = 7;

  const assets = popularAssets
    .filter((a) => a.volumeInUsd > 0)
    .sort((a, b) => {
      return parseFloat(b.volumeInUsd) - parseFloat(a.volumeInUsd);
    })
    .slice(0, maxCount);

  return (
    <div className="analytics__most-popular analytics__grid-item">
      <div className="analytics__grid-item-header">
        <div className="analytics__grid-item-title-cnt">
          <h3 className="analytics__grid-item-title">
            The most popular assets
            <SiteBadge />
          </h3>
        </div>
      </div>
      <div className="analytics__most-popular-slider most-pop-slider swiper">
        <div className="swiper-wrapper">
          {assets.map((assetInfo) => (
            <div
              key={assetInfo.id}
              className="most-pop-slider__item swiper-slide"
            >
              <div className="most-pop-slider__item-icon">
                <img
                  src={getTokenImg(assetInfo.crypto)}
                  // width="12"
                  height="20"
                  // alt=""
                />
              </div>
              <div className="most-pop-slider__item-title">
                <span className="most-pop-slider__item-token">
                  {assetInfo.crypto.title}
                </span>
                <span className="most-pop-slider__item-subscr">
                  {assetInfo.network.title}
                </span>
                <span className="most-pop-slider__item-value">
                  <span className="">
                    ${formatCash(assetInfo.volumeInUsd)}
                    <span className="tooltip tooltip_white">
                      {assetInfo.volumeInUsd}
                    </span>
                  </span>
                </span>
                <span className="most-pop-slider__item-subscr">
                  {formatCash(assetInfo.volume)} {assetInfo.crypto.symbol}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="most-pop-slider__nav-btn swiper-button-prev">
        <img src="/img/nav-prev-arrow.svg" width="6" height="12" alt="" />
      </button>
      <button className="most-pop-slider__nav-btn swiper-button-next">
        <img src="/img/nav-next-arrow.svg" width="6" height="12" alt="" />
      </button>
    </div>
  );
}
