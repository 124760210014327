import React, { useState } from "react";
import AmountTransferRow from "./AmountTransferRow";
import SiteBadge from "../../SiteBadge";

export default function AnalyticAmountTransfer({ networkCryptoDirections }) {
  const [selectedTab, setSelectedtab] = useState(1);
  const tabs = [
    {
      id: 1,
      title: "From",
      description: "The network from which the asset transfer occurred.",
    },
    {
      id: 2,
      title: "To",
      description: "The network to which the asset transfer occurred.",
    },
  ];
  const transfers =
    (selectedTab == 1
      ? networkCryptoDirections.from
      : networkCryptoDirections.to) || [];
  
  const maxCount = 10;

  const sortedTransfer = transfers
    .sort((a, b) => {
        return parseFloat(b.volumeInUsd) - parseFloat(a.volumeInUsd);
    })
    .slice(0, maxCount);

  return (
    <div className="analytics__largest-amount analytics__grid-item">
      <div className="analytics__grid-item-header">
        <div className="analytics__grid-item-title-cnt">
          <h3 className="analytics__grid-item-title">
            The largest transfer amount
            <SiteBadge />
          </h3>
        </div>
      </div>

      <div className="analytics__largest-amount-table analytics__grid-item-table">
        <div className="col-swapper col-swapper_mobile">
          {tabs.map((tab) => (
            <label key={tab.id}>
              <input
                tabIndex="-1"
                type="radio"
                name="amount-table-mob"
                defaultChecked={tab.id == selectedTab}
                onClick={() => setSelectedtab(tab.id)}
              />
              <span tabIndex="0">
                {tab.title}
                <button className="info-btn info-btn_outlined">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.66564 1.11087C3.61064 1.11087 1.1111 3.6104 1.1111 6.66539C1.1111 9.72039 3.61064 12.2199 6.66563 12.2199C9.72063 12.2199 12.2202 9.72039 12.2202 6.6654C12.2202 3.6104 9.72063 1.11087 6.66564 1.11087Z"
                      stroke="#2A4DFD"
                      strokeWidth="1.24977"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.66559 9.82135L6.66559 6.0661"
                      stroke="#2A4DFD"
                      strokeWidth="1.24977"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.66547 3.8116L6.66682 3.8116"
                      stroke="#2A4DFD"
                      strokeWidth="1.66636"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="tooltip tooltip_white">
                    {tab.description}
                  </span>
                </button>
              </span>
            </label>
          ))}
        </div>

        <div className="analytics__grid-item-table-header">
          <div className="index-col header-col">
            <span>#</span>
          </div>
          <div className="from-col header-col">
            <div className="col-swapper">
              {tabs.map((tab) => (
                <label key={tab.id}>
                  <input
                    tabIndex="-1"
                    type="radio"
                    name="amount-table"
                    defaultChecked={tab.id == selectedTab}
                    onClick={() => setSelectedtab(tab.id)}
                  />
                  <span tabIndex="0">
                    {tab.title}
                    <button className="info-btn info-btn_outlined">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.66564 1.11087C3.61064 1.11087 1.1111 3.6104 1.1111 6.66539C1.1111 9.72039 3.61064 12.2199 6.66563 12.2199C9.72063 12.2199 12.2202 9.72039 12.2202 6.6654C12.2202 3.6104 9.72063 1.11087 6.66564 1.11087Z"
                          stroke="#2A4DFD"
                          strokeWidth="1.24977"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.66559 9.82135L6.66559 6.0661"
                          stroke="#2A4DFD"
                          strokeWidth="1.24977"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.66547 3.8116L6.66682 3.8116"
                          stroke="#2A4DFD"
                          strokeWidth="1.66636"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="tooltip tooltip_white">
                        {tab.description}
                      </span>
                    </button>
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div className="asset-col header-col">
            <span>Asset</span>
          </div>
          <div className="amount-col header-col header-col_right">
            <span>Transfer amount</span>
          </div>
        </div>
        {sortedTransfer
          .map((transfer, index) => (
            <AmountTransferRow key={index} transfer={transfer} num={index + 1} />
          ))}
      </div>
    </div>
  );
}
