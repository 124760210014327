import React from "react";
import { formatCash, numberWithSpaces } from "../../../utils/NumberUtil";

export default function AmountTransferRow({ transfer, num }) {
  return (
    <div className="analytics__grid-item-table-row">
      <div className="index-col table-row">
        <span>{num}</span>
      </div>
      <div className="from-col table-row">
        <div className="token-cnt">
          <div className="token-cnt__icon icon-cnt">
            <img
              className="token-cnt__image"
              src="/img/polygon-icon.svg"
              alt=""
            />
            <div className="token-cnt__icon-bg"></div>
          </div>
          <div className="token-cnt__title">
            <span className="token-cnt__name">{transfer?.network.title}</span>
          </div>
        </div>
      </div>
      <div className="asset-col table-row">
        <img src="/img/asset-icon.png" width="20" height="20" alt="" />
        <div className="asset-col__name">
          <span className="asset-col__token">{transfer?.crypto.title}</span>
          <span className="asset-col__network">{transfer?.network.title}</span>
        </div>
      </div>
      <div className="amount-col table-row">
        <div className="amount-col__name">
          <span className="amount-col__sum">
            ${formatCash(transfer.volumeInUsd)}
          </span>
          <span className="amount-col__gas">
            {formatCash(transfer.volume)} {transfer?.crypto.symbol}
          </span>
        </div>
      </div>
    </div>
  );
}
