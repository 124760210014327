export const PERIOD_FILTERS = [
  {
    key: 1,
    title: "1D",
    num: 1,
    type: "day",
  },
  {
    key: 2,
    title: "1W",
    num: 1,
    type: "week",
  },
  {
    key: 3,
    title: "1M",
    num: 1,
    type: "month",
  },
  {
    key: 4,
    title: "1Y",
    num: 1,
    type: "year",
  },
];
