const desctopMenuItems = [
  {
    url: "https://app.chainspot.io/",
    title: "Router",
    isDisabled: false,
    isExternal: true,
  },
  {
    url: "/",
    title: "Gas Charger",
    isDisabled: false,
    liClass:'new',
    isGas:true,
    isExternal: true
  },
];
export function getMenuItems(isMobile = false) {
  return desctopMenuItems;
}
