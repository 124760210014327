import { VENOM_TESTNET } from "../services/venom/venomWalletWebProvider";
import {
  CHAIN_TYPE_EVM,
  CHAIN_TYPE_TVM,
  CHAIN_TYPE_TVM_VENOM,
} from "./ChainTypes";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_VENOM,
} from "./ProviderTypes";

export const DEF_TESTNET_CHAINS = [
  {
    sortId: 1,
    chainId: 1,
    title: "Etherium",
  },
];
