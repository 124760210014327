import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./assets/scss/style.scss";
import Layout from './Layout/Layout';
import routeList from './routes/routes';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route element={<Layout />}>
        {routeList.map((route, key) => (
          <Route key={key} path={route.path} element={<route.element />} />
        ))}
      </Route>
    </Routes>
  </BrowserRouter>

  );
}

export default App;
