export function isMobileDevice() {
  return "ontouchstart" in window || "onmsgesturechange" in window;
}

export function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, "", {
    "max-age": -1,
  });
}

export const isClientWelcomeTextRead = "isReadWelcomeText";
export const isCookeAccept = "isCookieAccept";
export const isDisclaimerRead = "isDisclaimerRead";

export function setCookieForever(key) {
  var expireTime = new Date(
    new Date().getTime() + 1000 * 60 * 60 * 24 * 365
  ).toGMTString();
  setCookie(key, 1, { expires: expireTime });
}

export function isCoockieSeted(key) {
  return getCookie(key) != undefined;
}
export function setWelcomRead() {
  setCookieForever(isClientWelcomeTextRead);
}

export function deleteWelcomeRead() {
  deleteCookie(isClientWelcomeTextRead);
}

export function isClientWelcomeRead() {
  return isCoockieSeted(isClientWelcomeTextRead);
}

/**
 * call yandex metrika
 * @param  {...any} args 
 */
export function ym(...args){
  if(window.ym){
    window.ym(...args)
  }
}

export const copyToBuffer = (text) => {
  navigator.clipboard.writeText(text);
};