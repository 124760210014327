import React, { useState } from "react";

export default function AnalyticDiagram() {
  return (
    <section className="data-v__diagram">
      <img src="img/diagram-image.png" width="971" height="898" alt="" />

      <div className="data-v__scale-control scale-control">
        <button className="scale-control__btn scale-control__btn_up"></button>
        <button className="scale-control__btn scale-control__btn_down"></button>
      </div>
    </section>
  );
}
