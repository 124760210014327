import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import WalletConnectButton, {
  WallectConnectedButtonBlock,
} from "../../../components/WalletConnectButton/WalletConnectButton";
import { getMenuItems } from "../../../data/menu";
import { toggleMobileMenu } from "../../../features/menu/menuSlice";
import {
  APP_URL,
  BLOG_LINK,
  TELEGRAM_GROUP,
  TWITTER_LINK,
} from "../../../constants/SocialLinks";
import {
  connectWallet,
  walletConnectDialogToggle,
} from "../../../features/walletService/walletService";

function MobileMenu() {
  const [links] = useState(getMenuItems(false));
  const isShowMenu = useSelector((state) => state.menuManagment.showMobileMenu);
  let dispatch = useDispatch();

  const activeClass = (route) => {
    return window.location.pathname === route ? "active" : null;
  };
  const connectedWallets = useSelector(
    (state) => state.walletServiceProvider.allWallets
  );

  return (
    <>
      <div className={"mobile-menu  " + (isShowMenu ? "active" : "")}>
        <div className="mobile-menu__header">
          <a className="logo-link" href="#">
            <img className="logo" src="img/chainspot-logo.png" alt="" />
          </a>
          <button
            className="mobile-menu__close"
            onClick={() => dispatch(toggleMobileMenu())}
          ></button>
        </div>
        <nav className="main-menu  mobile">
          <ul className="main-menu__items">
            {links.map((item, index) => (
              <li
                key={item.title}
                className={`main-menu__item  ${
                  !item.isDisabled && activeClass(item.url) ? "active" : ""
                } ${item.isDisabled ? "main-menu__item_disabled" : ""} ${
                  item.liClass ? item.liClass : ""
                } `}
              >
                <NavLink
                  to={item.url}
                  className="main-menu__link"
                  target="_blank"
                >
                  {item.url == "/gas" && (
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.92 11.2C18.561 11.2 18.2452 11.3392 18.0174 11.5732L18.0039 11.5867C17.7379 11.8464 17.5893 12.2162 17.6267 12.6089L17.6275 12.6177L17.6274 12.6177C17.6804 13.2527 18.2909 13.8 19.04 13.8H20.9828C21.1444 13.7896 21.25 13.6632 21.25 13.53V11.47C21.25 11.3368 21.1444 11.2104 20.9828 11.2H18.92ZM16.9493 10.52C17.4609 9.99815 18.1624 9.70001 18.92 9.70001H21C21.009 9.70001 21.0179 9.70017 21.0268 9.70049C21.9798 9.73453 22.75 10.5057 22.75 11.47V13.53C22.75 14.4943 21.9798 15.2655 21.0268 15.2995C21.0179 15.2998 21.009 15.3 21 15.3H19.04C17.6305 15.3 16.2624 14.2693 16.133 12.7467C16.0523 11.8845 16.3805 11.0791 16.9493 10.52Z"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 4.75C6.75575 4.75 6.52398 4.76744 6.30405 4.80128L6.28339 4.80416C5.17606 4.94312 4.30559 5.41721 3.70955 6.11651C3.11197 6.81761 2.75 7.79287 2.75 9V11C2.75 11.4142 2.41421 11.75 2 11.75C1.58579 11.75 1.25 11.4142 1.25 11V9C1.25 7.48713 1.70803 6.15239 2.56796 5.14349C3.42737 4.13519 4.64812 3.4999 6.08633 3.31713C6.38313 3.27204 6.68789 3.25 7 3.25H16C16.2697 3.25 16.5608 3.25975 16.8545 3.30711C18.305 3.47974 19.5395 4.1109 20.4103 5.12C21.2833 6.13163 21.75 7.4753 21.75 9V10.45C21.75 10.8642 21.4142 11.2 21 11.2H18.92C18.5609 11.2 18.2451 11.3392 18.0173 11.5732L18.0039 11.5867C17.7378 11.8464 17.5892 12.2162 17.6266 12.6089L17.6275 12.6177L17.6274 12.6177C17.6803 13.2527 18.2909 13.8 19.04 13.8H21C21.4142 13.8 21.75 14.1358 21.75 14.55V16C21.75 17.6704 21.1899 19.1207 20.1553 20.1553C19.1207 21.1899 17.6704 21.75 16 21.75H13.5C13.0858 21.75 12.75 21.4142 12.75 21C12.75 20.5858 13.0858 20.25 13.5 20.25H16C17.3296 20.25 18.3793 19.8101 19.0947 19.0947C19.8101 18.3793 20.25 17.3296 20.25 16V15.3H19.04C17.6304 15.3 16.2623 14.2693 16.133 12.7467C16.0522 11.8845 16.3805 11.079 16.9493 10.52C17.4609 9.99815 18.1623 9.70001 18.92 9.70001H20.25V9C20.25 7.7847 19.8817 6.80336 19.2747 6.09999C18.6691 5.39822 17.7851 4.92541 16.6634 4.79497C16.6511 4.79354 16.6389 4.79181 16.6267 4.78978C16.4468 4.7598 16.2446 4.75 16 4.75H7Z"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.14876 10.0713C9.51655 10.2094 9.73379 10.5052 9.8497 10.7857C9.96411 11.0625 10.0063 11.3791 10.0063 11.7V15.45H11.1875C11.7172 15.45 12.4051 15.6114 12.6913 16.2452C12.9777 16.8793 12.6433 17.501 12.2934 17.8964C12.2932 17.8967 12.2929 17.897 12.2927 17.8973L7.56297 23.2705C7.35101 23.5112 7.11016 23.721 6.84181 23.8533C6.57027 23.9872 6.21237 24.0666 5.84499 23.9287C5.4772 23.7906 5.25996 23.4949 5.14405 23.2144C5.02965 22.9375 4.9875 22.6209 4.9875 22.3V18.55H3.80625C3.28025 18.55 2.5942 18.3906 2.30751 17.7581C2.02159 17.1272 2.35117 16.5058 2.69609 16.1084L2.69952 16.1044L7.43078 10.7295C7.64274 10.4889 7.88359 10.2791 8.15194 10.1467C8.42348 10.0128 8.78138 9.93337 9.14876 10.0713ZM8.50625 11.7779L3.86556 17.05H5.7375C6.15171 17.05 6.4875 17.3858 6.4875 17.8V22.2221L11.1282 16.95H9.25625C8.84204 16.95 8.50625 16.6142 8.50625 16.2V11.7779Z"
                      />
                    </svg>
                  )}
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="mobile-menu__socials">
          <a href={TELEGRAM_GROUP} target="_blank">
            <img src="img/telegram-icon.svg" alt="" />
          </a>
          <a href={TWITTER_LINK} target="_blank">
            <img src="img/twitter-icon.svg" alt="" />
          </a>
        </div>
        <div className="mobile-menu__cta">
          {connectedWallets.length == 0 && (
            <a
              className="btn btn_"
              href="#"
              onClick={() => dispatch(walletConnectDialogToggle())}
            >
              Connect wallet
            </a>
          )}
          {connectedWallets.length > 0 && <WallectConnectedButtonBlock />}
        </div>
      </div>
    </>
  );
}
export default MobileMenu;
